import { INSTANCE_TYPES } from '../constants/instance-types';
import { getPrefixedUrl } from '../helpers/get-prefixed-url';
import { getRedirectionUrlService } from '../services/get-redirection-url.service';
import { IRedirectionParams, REDIRECTION_TYPES } from '../types';
import { getRedirectionTokenService } from '@root/modules/auth/services';
import { metaSelector } from '@root/modules/meta/redux';
import { externalRedirect, notify } from '@root/shared/utils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useRedirection = () => {
  const metaState = useSelector(metaSelector);

  const redirectionWithTokenTypes = useMemo(
    () => [
      REDIRECTION_TYPES.RENEW,
      REDIRECTION_TYPES.BUY,
      REDIRECTION_TYPES.SWITCH_TO_PLAN,
      REDIRECTION_TYPES.VIEW_ITEM_ON_SHOP,
      REDIRECTION_TYPES.RETRY,
      REDIRECTION_TYPES.REQUEST_ORDER,
      REDIRECTION_TYPES.TOPUP,
      REDIRECTION_TYPES.SHOP,
      REDIRECTION_TYPES.SHOP_CHECKOUT,
      REDIRECTION_TYPES.MHA_LOGIN_SS0,
    ],
    [],
  );

  const redirectionWithToken = useCallback(async (type: REDIRECTION_TYPES, params: Partial<IRedirectionParams>) => {
    let instanceType = INSTANCE_TYPES.SHOP;
    if (type === REDIRECTION_TYPES.MHA_LOGIN_SS0) {
      instanceType = INSTANCE_TYPES.MHA_LOGIN;
    }
    if (params.isAnnual) {
      instanceType = INSTANCE_TYPES.ANNUAL_SHOP;
    }

    const response = await getRedirectionTokenService(instanceType);
    if (response.status !== 200) {
      notify({ type: 'danger', title: 'Error', text: response.payload });
      return;
    }

    const url = getPrefixedUrl(response.payload.redirectionUrl);
    const token = response.payload.redirectionToken;

    if (type === REDIRECTION_TYPES.RENEW) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&planId=${params.planId}&action=renew&quantity=${params.quantity || 1}`);
      return;
    }

    if (type === REDIRECTION_TYPES.BUY) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&planId=${params.planId}&action=initial`);
      return;
    }

    if (type === REDIRECTION_TYPES.SWITCH_TO_PLAN) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&switchPlanId=${params.planId}`);
      return;
    }

    if (type === REDIRECTION_TYPES.VIEW_ITEM_ON_SHOP) {
      externalRedirect(`${url}/products/online-learning/${params.urlParam}/${params.planId}/?tempTok=${token}`);
      return;
    }

    if (type === REDIRECTION_TYPES.RETRY) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&retryOrderId=${params.retryOrderId}`);
      return;
    }

    if (type === REDIRECTION_TYPES.REQUEST_ORDER) {
      externalRedirect(`${url}/checkout/?requestToken=${token}&requestedOrderId=${params.requestOrderId}`);
      return;
    }

    if (type === REDIRECTION_TYPES.TOPUP) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&action=initial&topUpAmount=${params.topUpAmount}`);
      return;
    }

    if (type === REDIRECTION_TYPES.SHOP) {
      externalRedirect(`${url}/products/online-learning?tempTok=${token}`);
      return;
    }

    if (type === REDIRECTION_TYPES.SHOP_CHECKOUT) {
      externalRedirect(`${url}/checkout/?tempTok=${token}&quantity=${params.quantity || 1}&topUpAmount=${params.topUpAmount}&urlParam=gifted-cards`);
      return;
    }

    //TODO - check if we need this for redirection to services
    if (type === REDIRECTION_TYPES.MHA_LOGIN_SS0) {
      externalRedirect(`${url}/?tempTok=${token}`);
      return;
    }

    externalRedirect(`${url}/?tempTok=${token}`);
    return;
  }, []);

  const redirectionWithoutToken = useCallback(
    async (type: REDIRECTION_TYPES, params: Partial<IRedirectionParams>) => {
      if (type === REDIRECTION_TYPES.REGISTRATION) {
        // TODO - temporary added 'CO' country, remove after BE will fix currentInstance for localhost origin
        const response = await getRedirectionUrlService({ instanceType: INSTANCE_TYPES.SHOP, countryCode: metaState.currentInstance?.baseCountry?.code || 'CO' });

        if (response.status === 200) {
          if (response.payload) {
            const url = getPrefixedUrl(response.payload);
            externalRedirect(`${url}/signup`);
          }
        } else {
          notify({ type: 'danger', title: 'Error', text: response.payload });
        }
        return;
      }

      return;
    },
    [metaState],
  );

  const redirect = useCallback(
    (type: REDIRECTION_TYPES, params: Partial<IRedirectionParams> = {}) => {
      if (redirectionWithTokenTypes.includes(type)) {
        redirectionWithToken(type, params).then(console.log);
      } else {
        redirectionWithoutToken(type, params).then(console.log);
      }
    },
    [redirectionWithToken, redirectionWithoutToken, redirectionWithTokenTypes],
  );

  return { redirect };
};
