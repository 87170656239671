import { SidebarUI } from '../components/sidebar/sidebar.ui';
import { useSidebar } from '../hooks';
import { HelpCenter } from '@root/modules/auth/components';
import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { useUserDropdownKycStatus } from '@root/modules/kyc/hooks/use-userdropdown-kyc-status';
import { menuKYCWarningSelector, selectIsNavigationEnabled } from '@root/modules/kyc/redux/kyc.selector';
import { useLegalDocument } from '@root/modules/meta/hooks/use-legal-document';
import { OrdersTabKey } from '@root/modules/orders/constants/orders-tab-key';
import { IncreaseEWalletBalance } from '@root/modules/payment-methods/containers/increase-e-wallet-balance';
import { menuSubscriptionsWarningSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { BodyText } from '@root/shared/ui';
import { WarningBadge } from '@root/shared/ui/components/warning-badge';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { DashboardIcon } from '@root/shared/ui/icons/dashboard-icon';
import { GearIcon } from '@root/shared/ui/icons/gear-icon';
import { GiftCardsIcon } from '@root/shared/ui/icons/gift-cards-icon';
import { KycIcon } from '@root/shared/ui/icons/kyc-icon';
import { OrdersIcon } from '@root/shared/ui/icons/orders-icon';
import { PaymentMethodsIcon } from '@root/shared/ui/icons/payment-methods-icon';
import { ProductsIcon } from '@root/shared/ui/icons/products-icon';
import { RedirectIcon } from '@root/shared/ui/icons/redirect-icon';
import { StackIcon } from '@root/shared/ui/icons/stack-icon';
import { SubmitTicketIcon } from '@root/shared/ui/icons/submit-ticket-icon';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Wrapper, Header, Menu, Toggle, ToggleIcon, ToggleBrand, Link, LinkIcon, LinkTitle, AccordionItem, AccordionLink } = SidebarUI;

export const Sidebar = () => {
  const { t } = useTranslation();
  const [{ isOpen }, { toggle }] = useSidebar();
  const [, { open: openLegalDocument }] = useLegalDocument();
  const [{ status }] = useUserDropdownKycStatus();
  const { redirect } = useRedirection();

  const showSubscriptionWarning = useSelector(menuSubscriptionsWarningSelector);
  const showKycWarning = useSelector(menuKYCWarningSelector);
  const showKycMenuItem = !!status;
  const isNavigationEnabled = useSelector(selectIsNavigationEnabled);

  const redirectToShop = useCallback(
    (e) => {
      e.preventDefault();
      redirect(REDIRECTION_TYPES.SHOP);
    },
    [redirect],
  );

  return (
    <Wrapper isOpen={isOpen}>
      <Header>
        <Toggle onClick={toggle}>
          <ToggleIcon>
            <ChevronRightIcon className={clsx('transform transition', { 'rotate-180': isOpen })} />
          </ToggleIcon>
          <ToggleBrand>
            <BodyText>{t('Hide_menu', { ns: 'common' })}</BodyText>
          </ToggleBrand>
        </Toggle>
      </Header>
      <Menu isDisabled={!isNavigationEnabled}>
        <Link to='/' end>
          <LinkIcon>
            <DashboardIcon />
          </LinkIcon>
          <LinkTitle>{t('Dashboard', { ns: 'common' })}</LinkTitle>
        </Link>
        <Link to='/products'>
          <LinkIcon>
            <StackIcon />
          </LinkIcon>
          <LinkTitle>{t('Products_and_Services', { ns: 'common' })}</LinkTitle>
        </Link>
        <Link to='/orders' state={{ openedTab: OrdersTabKey.ORDERS }}>
          <LinkIcon>
            <OrdersIcon />
          </LinkIcon>
          <LinkTitle>{t('Account_orders', { ns: 'common' })}</LinkTitle>
        </Link>
        <Link to='/payment-methods'>
          <LinkIcon>
            <PaymentMethodsIcon />
          </LinkIcon>
          <LinkTitle>{t('payment_methods', { ns: 'common' })}</LinkTitle>
        </Link>
        <AccordionItem
          isSidebarOpen={isOpen}
          toggleSidebar={toggle}
          label={
            <div className='flex items-center'>
              <LinkIcon>
                <ProductsIcon />
              </LinkIcon>
              <LinkTitle>
                {t('Subscriptions', { ns: 'common' })}
                {showSubscriptionWarning && <WarningBadge content='!' className='ml-2' />}
              </LinkTitle>
            </div>
          }
        >
          <AccordionLink to='/subscriptions'>
            <LinkTitle>
              {t('My Subscriptions', { ns: 'common' })}
              {isOpen && showSubscriptionWarning && <WarningBadge content='!' className='ml-2' />}
            </LinkTitle>
          </AccordionLink>
          <AccordionLink to='/membership'>
            <LinkTitle>{t('Membership', { ns: 'common' })}</LinkTitle>
          </AccordionLink>
        </AccordionItem>
        {showKycMenuItem && (
          <Link to='/kyc'>
            <LinkIcon>
              <KycIcon />
            </LinkIcon>
            <LinkTitle>
              {t('KYC verification', { ns: 'common' })}
              {showKycWarning && <WarningBadge content='!' className='ml-2' />}
            </LinkTitle>
          </Link>
        )}
        {/*<Link to='/gift-cards'>*/}
        {/*  <LinkIcon>*/}
        {/*    <GiftCardsIcon />*/}
        {/*  </LinkIcon>*/}
        {/*  <LinkTitle>{t('Gift_Cards', { ns: 'common' })}</LinkTitle>*/}
        {/*</Link>*/}
        <Link to='/settings'>
          <LinkIcon>
            <GearIcon />
          </LinkIcon>
          <LinkTitle>{t('AccountSettings', { ns: 'common' })}</LinkTitle>
        </Link>

        <HelpCenter>
          <div className='flex items-center py-5 cursor-pointer hover:bg-opacity-50 hover:bg-primary-400'>
            <LinkIcon>
              <SubmitTicketIcon />
            </LinkIcon>
            <LinkTitle>{t('Submit_a_ticket', { ns: 'common' })}</LinkTitle>
          </div>
        </HelpCenter>

        <Link className='redirect_to_shop_button' to='/shop' onClick={redirectToShop}>
          <LinkIcon>
            <RedirectIcon />
          </LinkIcon>
          <LinkTitle>{t('common:Shop')}</LinkTitle>
        </Link>
      </Menu>

      <Menu className='sticky flex items-center justify-center bottom-6 mt-auto py-2.5 mb-6 bg-primary-700' isDisabled={!isNavigationEnabled}>
        <div className={'flex items-center justify-start mx-4'}>
          <IncreaseEWalletBalance sidebar textVisible={isOpen} />
        </div>
      </Menu>

      <Menu className='mx-8 pb-6' isDisabled={!isNavigationEnabled}>
        <div className={clsx('flex items-center justify-start gap-4 transition opacity-100 text-xs', { 'hidden opacity-0': !isOpen })}>
          <div className='cursor-pointer max-w-1/2 truncate'>
            <button onClick={() => openLegalDocument('privacy_policy')}>
              <span className='hover:text-primary-300'>{t('privacy_policy')}</span>
            </button>
          </div>
          <div className='cursor-pointer max-w-1/2 truncate'>
            <button onClick={() => openLegalDocument('terms_of_use')}>
              <span className='hover:text-primary-300'>{t('common:terms_of_use')}</span>
            </button>
          </div>
        </div>
      </Menu>
    </Wrapper>
  );
};
